<template>
  <div>
    <el-card class="intanle">
      <span class="h1">{{ title }}</span>
      <span @click="goBack()">
        <el-button class="el-button1" type="info" style="float: right">戻る</el-button>
      </span>
    </el-card>
    <el-card>
      <!-- 情報入力 -->
      <el-form ref="form" label-position="right" label-width="20%" :rules="rules" :model="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="名前:" prop="name">
              <el-input placeholder="名前を入力してください" v-model="form.name" :disabled="disabled" style="width: 45%"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="携帯:" prop="mobile">
              <el-input placeholder="携帯番号を入力してください" :disabled="disabled" v-model="form.mobile" onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="11" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-if="type == 'insert'">
            <el-form-item label="パスワード:" prop="password">
              <el-input placeholder="パスワードを入力してください" v-model="form.password" :disabled="disabled" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.role == 2">
            <el-form-item label="商店:" prop="merchantName">
              <el-input placeholder="商店を選択してください" v-model="form.merchantName" :disabled="true" style="width: 45%" @focus="MerchantsTable = true"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="役割:" prop="role">
              <el-radio-group v-model="form.role" :disabled="true">
                <el-radio :label="'1'">システム管理者</el-radio>
                <el-radio :label="'2'">商店管理者</el-radio>
                <!-- <el-radio :label="'3'">业务员</el-radio>
                                <el-radio :label="'4'">客服</el-radio> -->
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="利用可能か:" prop="enabled">
              <el-radio-group v-model="form.enabled" :disabled="disabled">
                <el-radio label="1">はい</el-radio>
                <el-radio label="0">いいえ</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="logtime" v-if="this.type != 'insert'">
          <el-col :span="12">
            <el-form-item label="最終ログインIP:" label-width="20%">
              {{ lastAccessIp }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最終ログイン時間:" label-width="20%">
              {{ lastAccessTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作成日時:" label-width="20%">
              {{ createTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新日時:" label-width="20%">
              {{ updateTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作成者:" label-width="20%">
              {{ createPersonName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新者:" label-width="20%">
              {{ updatePersonName }}
            </el-form-item>
          </el-col>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="" v-if="this.type != 'details'">
              <el-button type="primary" @click="onSubmit('form')">{{ content }}</el-button>
              <el-button @click="goBack">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- 相关商户弹出层 -->
      <el-dialog :visible.sync="MerchantsTable" direction="rtl" size="50%" width="80%" center>
        <MERCHARTS :istanchuang="istanchuang" ref="eidtList" @submitForm="submitForm"></MERCHARTS>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import md5 from 'js-md5'
import MERCHARTS from '../Merchants/GetMerchantsList.vue'
import staffService from '@/api/staff/staffService'
export default {
  inject: ['reload'],
  name: 'GetStaffDetails',
  components: {
    MERCHARTS
  },
  data() {
    return {
      staffService: new staffService(), //インターフェース定義 工作人员
      type: this.$route.query.type, //遷移パラメータ
      staffId: this.$route.query.staffId, // スタッフID
      MerchantsTable: false, //ポップアップ表示
      loading: false, //ローディング

      istanchuang: true, // 親子通信パラメータ
      form: {
        name: '',
        password: '',
        role: '1',
        merchantId: '',
        mobile: '',
        merchantName: '',
        enabled: ''
      },
      title: '', // 詳細タイトル
      content: '', //ボタンテキスト
      MyHeader: {
        accessToken: '',
        fp: ''
      },
      disabled: false, //編集可能かどうか
      lastAccessIp: '', //最終ログイン時間
      lastAccessTime: '', //最后登录时间
      createTime: '', //作成日時
      updateTime: '', //更新日時
      createPersonName: '', //创建人
      updatePersonName: '', //更新人
      headerName: '', //パンくずリストのタイトル
      // 非空のバリデーション
      rules: {
        name: [
          {
            required: true,
            message: '名前を入力してください',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: 'パスワードを入力してください',
            trigger: 'blur'
          }
        ],
        enabled: [
          {
            required: true,
            message: '利用可能かどうかを入力してください',
            trigger: 'change'
          }
        ],
        mobile: [
          {
            required: true,
            message: '電話番号を入力してください',
            trigger: 'blur'
          },
          { min: 8, max: 15, message: '電話番号の形式が正しくありません', trigger: 'blur' }
        ]
      }
    }
  },

  created() {
    this.MyHeader.accessToken = localStorage.getItem('accessToken')
    this.MyHeader.fp = localStorage.getItem('fp')
    if (this.type == 'details') {
      this.title = 'スタッフ詳細'
      this.headerName = 'スタッフ詳細'
      this.disabled = true
      this.GetStaffDetail()
    } else if (this.type == 'update') {
      this.title = 'スタッフ編集'
      this.headerName = 'スタッフ編集'
      this.content = '保存'
      this.GetStaffDetail()
    } else if (this.type == 'insert') {
      this.title = 'スタッフ新規追加'
      this.headerName = 'スタッフ新規追加'
      this.content = '追加'
    }
  },

  methods: {
    //获取スタッフ詳細
    async GetStaffDetail() {
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        staffId: this.staffId, //スタッフ
        currentPage: this.currentPage, //ページ数
        pageSize: this.pageSize //1ページあたりの件数
      }
      this.staffService.detail(date).then(res => {
        if (res.data.success == 'true') {
          this.form = res.data.result
          this.$set(this.form, this.form.enabled, res.data.result.enabled)
          this.lastAccessIp = res.data.result.lastAccessIp //最終ログインIP
          this.lastAccessTime = res.data.result.lastAccessTime // 最終ログイン時間
          this.createTime = res.data.result.createTime
          this.updateTime = res.data.result.updateTime
          this.createPersonName = res.data.result.updatePersonName
          this.updatePersonName = res.data.result.updatePersonName
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    //スタッフ新規
    onSubmit(formName) {
      if (this.type == 'insert') {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            const date = {
              accessToken: localStorage.getItem('authToken'),
              currentRole: localStorage.getItem('currentRole'),
              name: this.form.name, // スタッフ名
              mobile: this.form.mobile, //スタッフ電話番号
              password: md5(this.form.password), // スタッフパスワード
              role: this.form.role, // 権限 1.システム管理者  2.商人管理者
              merchantId: this.form.merchantId //商店ID
            }
            this.staffService.add(date).then(res => {
              if (res.data.success == 'true') {
                this.$message.success('保存に成功しました')
                this.goBack()
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
        })
      } else if (this.type == 'update') {
        this.UpdateStaff(formName)
      }
    },
    //内部スタッフの編集
    UpdateStaff(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const date = {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            staffId: this.staffId,
            name: this.form.name, //スタッフ名
            mobile: this.form.mobile, // スタッフ電話番号
            enabled: this.form.enabled, //権限 1.システム管理者  2.商人管理者
          }
          this.staffService.update(date).then(res => {
            if (res.data.success == 'true') {
              this.$message.success('保存に成功しました')
              this.goBack()
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          return false
        }
      })
    },
    //前のページに戻る
    goBack() {
      localStorage.setItem('updateState', true)
      this.$router.go(-1)
    },
    // ポップアップの確認ボタン
    submitForm() {
      var item = this.$refs['eidtList'].passFather()
      if (item == null) {
        item = []
      }
      if (item.length != 0) {
        // 子コンポーネントの入力内容を取得
        this.form.merchantName = item.merchantName
        this.form.merchantId = item.merchantId
      }
      this.MerchantsTable = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-button1 {
  margin-top: 2px;
}

.el-card {
  margin-top: 15px;
}

textarea {
  height: 100px;
}
</style>
